<template>
  <header class="header">
    <div class="div-header-img">
      <img src="./../images/logo-seall.png" alt="Logo SEALL" class="img-logo" />
      <div class="div-header-links">
        <a
          href="/esg"
          class="header-link"
          v-bind:class="{ 'seall-active': 'esg' === currentRouteName }"
        >
          {{ $t("header.esg") }}
        </a>
        <a
          href="/esg/radar"
          class="header-link"
          v-bind:class="{ 'seall-active': 'esg-radar' === currentRouteName }"
        >
          Radar ESG
        </a>
        <a @click.prevent="logout()" class="header-link cursor-pointer">
          {{ $t("main.logout") }}
        </a>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "EsgHeader",
  props: ["organization"],
  data() {
    return {
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name.toLowerCase();
    },
    organizationComputed() {
      if (this.organization != null && this.organization.id != null) {
        return this.organization;
      }
      return JSON.parse(localStorage.getItem("organization"));
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    set_lang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
}

.div-header-img {
  display: flex;
  align-items: center;
}

.router-link-exact-active {
  color: #2f1764 !important;
}

.div-header-links {
  text-decoration: none;
  display: flex;
  flex-direction: row;
}

.header-link {
  color: #a3a3a3;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  padding: 0vh 2vw;
  text-decoration: none;
}

.logos {
  display: flex;
  align-items: center;
  img {
    width: 30%;
    padding: 1rem;
  }
}

.header-button {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 1vh 3vw;
}

.right-side {
  display: flex;
  flex-direction: row;
}

.div-header-photo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.div-header-photo i {
  padding: 0vh 1vw 0vh 3vw;
}

.div-header-photo img {
  border-radius: 100%;
  overflow: hidden;
  max-width: 71px;
  max-height: 71px;
}
.header-div-langs {
  margin: 0vh 0.5vw;
  display: flex;
  align-items: center;
}
</style>